<template>
  <div id="app">
    <img src="/logo.png" width="15%" height="15%" class="float-right m-5">
    <b-jumbotron header="EkonAudit.eu" lead="Virtuálne školiace stredisko" class="bg-white mb-0 pb-3">
      <p class="text-muted text-bold">Vitajte vo virtuálnom školiacom stredisku firmy EKONAUDIT, s.r.o. Ďakujeme za zakúpenie školenia. Vám pridelené školenia sa zobrazia nižšie po prihlásení. Prihlasovacie údaje ste obdržali e-mailom. V prípade akýchkoľvek problémov s prehrávaním obsahu sa môžete obrátiť na podporu <a class="text-info" href="mailto:ekonaudit@gmail.com">ekonaudit@gmail.com</a> alebo telefonicky 0902 820 400.</p>
    </b-jumbotron>
    <div id="container">

      <div class="row">

        <div class="col-sm">

      <b-card header-tag="header" class="mb-2 mx-auto" style="width:730px;">
        <template #header class="m-0 p-0" v-if="logged">
          <div id="vue-core-video-player-box" class="player">
            <video-player  class="vjs-custom-skin"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"></video-player>
          </div>
        </template>
        <b-card-text>
          <div v-if="logged" class="btn-group float-right">
            <a href="#" class="btn btn-sm" :class="classQuality(false)" @click="toggleQuality(false)"><strong>SD</strong></a>
            <a href="#" class="btn btn-sm" :class="classQuality(true)" @click="toggleQuality(true)"><strong>HD</strong></a>
          </div>
          <h3>Transformácia účtovného výsledku hospodárenia</h3>
          <p v-if="!logged">Aby ste mohli sledovať Vám pridelené školenie, zadajte prosím Vaše prihlasovacie údaje.</p>
          <div v-if="logged">
            <p class="mb-0">Ing. Jana Gregorová</p>
            <p class="mb-0"><small class="text-muted">Publikované 26. januára 2022</small></p>
          </div>
        </b-card-text>
        <div id="loginform" v-if="!logged">
          <b-form-input v-model="email" type="email" placeholder="Zadajte svoj e-mail" class="mx-3 mb-1 w-50"></b-form-input>
          <b-form-input v-model="password" type="password" placeholder="Zadajte heslo" class="mx-3 mb-1 w-50"></b-form-input>
          <b-button variant="secondary" href="#" @click="authenticate()" class="ml-3" style="width:120px;">Prihlásiť</b-button>
          <div class="alert alert-danger mx-3 mt-3 mb-1" role="alert" v-if="errorMsg">{{ errorMsg }}</div>
        </div>
      </b-card>

        </div>
        <!--<div class="col-sm">

      <b-card header-tag="header" class="mb-2 mx-auto" style="width:730px;" v-if="logged">
        <template #header class="m-0 p-0" v-if="logged">
          <div id="vue-core-video-player-box" class="player">
            <video-player  class="vjs-custom-skin"
                 ref="videoPlayer"
                 :options="playerOptionsAlt"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"></video-player>
          </div>
        </template>
        <b-card-text>
          <div v-if="logged" class="btn-group float-right">
            <a href="#" class="btn btn-sm" :class="classQualityAlt(false)" @click="toggleQualityAlt(false)"><strong>SD</strong></a>
            <a href="#" class="btn btn-sm" :class="classQualityAlt(true)" @click="toggleQualityAlt(true)"><strong>HD</strong></a>
          </div>
          <h3>Účtovná závierka pre podnikateľov</h3>
          <div v-if="logged">
            <p class="mb-0">Ing. Jana Gregorová</p>
            <p class="mb-0"><small class="text-muted">Publikované 26. januára 2022</small></p>
          </div>
        </b-card-text>
      </b-card>

        </div>-->
        </div>

      <Administrator v-if="token"/>

    </div>
    <div class="my-5 text-center">
      <p class="mb-0"><small class="text-muted">Media content EKONAUDIT, s.r.o., &copy;2021-2022 All Rights Reserved.</small></p>
      <p class="mb-0"><small class="text-muted">Software and content hosting by Mediaware, s.r.o., &copy;2021-2022 All Rights Reserved.</small></p>
    </div>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Administrator from './components/Administrator.vue'
import axios from 'axios';

export default {
  name: 'App',

  components: {
    Administrator
  },

  data() {
    return {
      email: null,
      password: null,
      errorMsg: null,
      loginform: false,
      logged: false,
      quality: true,
      qualityAlt: true,
      source: '',
      sourcesd: '',
      sourcealt: '',
      sourcealtsd: '',
      token: null,
      backend: '/api/v1',
      //backend: 'http://localhost:9999/api/v1',
      playerOptions: {
          width: '730',
          height: '410',
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [],
          poster: '/logo.png'
        },
      playerOptionsAlt: {
          width: '730',
          height: '410',
          muted: false,
          language: 'en',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [],
          poster: '/logo.png'
        }
    }
  },

  computed: {
      player() {
        return this.$refs.videoPlayer.player
      }
  },

  methods: {

    authenticate() {
      this.errorMsg = null;
      var that = this;
      axios.post(`${this.backend}/authenticate`, {
          email: this.email,
          password: this.password
      }).then(response => {
          that.source = response.data.source;
          that.sourcesd = response.data.sourcesd;
          that.sourcealt = response.data.sourcealt;
          that.sourcealtsd = response.data.sourcealtsd;
          that.token = response.data.token;
          that.playerOptions.sources = [
            {
                type: "video/mp4",
                src: response.data.source
            }
          ];
          that.playerOptionsAlt.sources = [
            {
              type: "video/mp4",
              src: response.data.sourcealt
            }
          ];
          that.logged = true;
      }).catch(function(error) {
          that.errorMsg = error;
      });
    },

    toggleQuality(quality) {
      this.quality = quality;
      this.playerOptions.sources[0].src = quality ? this.source : this.sourcesd;
    },

    toggleQualityAlt(quality) {
      this.qualityAlt = quality;
      this.playerOptionsAlt.sources[0].src = quality ? this.sourcealt : this.sourcealtsd;
    },

    classQuality(element) {
      return element == this.quality ? 'btn-secondary active' : 'btn-outline-secondary';
    },

    classQualityAlt(element) {
      return element == this.qualityAlt ? 'btn-secondary active' : 'btn-outline-secondary';
    }

  }

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.player {
    margin: 0;
    position: relative;
    width: 730px;
    height: 410px;
    background-color: white;
    font-weight: normal;
}

.vjs-poster {
    background-color: white !important;
}

.card-header {
  margin: 0;
  padding: 0;
}

</style>
